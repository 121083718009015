import React, { Component } from 'react'

import './style.css'

class Button extends Component {
  constructor (props) {
    super(props)

    this.clickHandler = this.clickHandler.bind(this)
  }

  clickHandler () {
    if (this.props.data.callback) {
      this.props.data.callback()
    }
  }

  render () {
    const props = this.props.data
    const component = props.link ? <a className='z-button' href={props.link}>{props.value}</a> : <button className='z-button' onClick={this.clickHandler}>{props.value}</button>

    return (
      component
    )
  }
}

export default Button
